import React, { createContext, useContext, useState, ReactNode } from "react";

interface UpdateContextProps {
  update: boolean;
  triggerUpdate: () => void;
}

const UpdateContext = createContext<UpdateContextProps | undefined>(undefined);

export const UpdateProvider = ({ children }: { children: ReactNode }) => {
  const [update, setUpdate] = useState(false);

  const triggerUpdate = () => setUpdate((prev) => !prev);

  return (
    <UpdateContext.Provider value={{ update, triggerUpdate }}>
      {children}
    </UpdateContext.Provider>
  );
};

export const useUpdateContext = () => {
  const context = useContext(UpdateContext);
  if (!context) {
    throw new Error("useUpdateContext must be used within an UpdateProvider");
  }
  return context;
};
