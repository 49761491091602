import React from "react";
import { ArrowLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import logo from "../assets/logo/logo_bassist.png";

export default function NotFound() {
  const navigate = useNavigate();
  const { user } = useAuth();
  function handleGoBack() {
    navigate(user ? "/children" : "/login");
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white flex items-center justify-center px-4">
      <div className="max-w-lg w-full text-center">
        {/* Logo */}
        <div className="mb-8 relative">
          <div className="absolute inset-0 bg-blue-100 rounded-full blur-xl opacity-50"></div>
          <img
            src={logo}
            alt="Bassist Logo"
            className="h-32 mx-auto relative transform rotate-45 animate-[bounce_2s_infinite]"
          />
        </div>

        {/* Texte principal */}
        <h1 className="text-6xl font-bold text-gray-900 mb-4">Oups !</h1>
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          Page Non Trouvée
        </h2>
        <p className="text-gray-600 mb-8">
          Houston, nous avons un problème ! Cette page semble s&apos;être
          envolée dans l&apos;espace ! 🌟
        </p>

        {/* Bouton de retour */}
        <button
          onClick={handleGoBack}
          className="inline-flex items-center px-6 py-3 text-lg font-medium text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-4 focus:ring-blue-300 transition-colors duration-200"
        >
          <ArrowLeft className="w-5 h-5 mr-2" />
          Retour
        </button>
      </div>
    </div>
  );
}
