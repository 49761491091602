/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { CardMonitoring } from "../../component/Cards";
import axios from "../../lib/config/axios";
import { useAuth } from "../../hooks/useAuth";
import { enqueueSnackbar, useSnackbar } from "notistack";
import { availablesMonitorings } from "./constants";
import ArrowLeft from "../../assets/icons/Arrow/ArrowLeft";
import ArrowRight from "../../assets/icons/Arrow/ArrowRight";
import Header from "../../component/Header";
import Balance from "../../assets/icons/58-removebg-preview.png";
import { ButtonIcon } from "../../component/Button";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface WeightType {
  id: string;
  dateTime: string;
  value: number;
}

const WeightModal = ({
  setOpen,
  setTemp,
  setTempData,
  monitoring,
  id,
  user,
}: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const [form, setForm] = useState<any>({});

  const matchingForm = availablesMonitorings[3].form;

  const postUrl = availablesMonitorings
    .find((m) => m.name === monitoring)
    ?.postUrl(id);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!postUrl) return;
    const response = await axios.post(
      postUrl,
      { ...form, dateTime: new Date().toISOString() },
      {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      }
    );

    if (response.status === 201) {
      setOpen(false);
      setTemp((prevState: any) => [...prevState, response.data]);
      setTempData((prevState: any) => [...prevState, response.data]);
      enqueueSnackbar("Une notification appropriée apparaîtra ici", {
        autoHideDuration: 2000,
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } else {
      console.log("error");
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50">
      <div className="bg-white w-2/3 md:w-1/2 xl:w-1/3 p-8 rounded-lg">
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-semibold text-blue-400 mb-4">
            Ajouter un poids
          </h2>
          <button onClick={() => setOpen(false)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <form className="flex flex-col mt-4 gap-4" onSubmit={handleSubmit}>
          {matchingForm?.fields.map((field: any) => (
            <div key={field.name} className="flex flex-col gap-2">
              <label htmlFor={field.name}>{field.name}</label>
              {field.type === "select" ? (
                <select
                  value={form[field.field_name]}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      [field.field_name]: e.target.value,
                    })
                  }
                  id={field.name}
                  name={field.name}
                  className="border border-gray-300 py-2 px-4 rounded-lg shadow-sm focus:ring-1 focus:ring-blue-500 focus:outline-none"
                >
                  {field.options.map((option: string) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  value={form[field.field_name]}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      [field.field_name]:
                        field.type === "number"
                          ? Number(e.target.value)
                          : e.target.value,
                    })
                  }
                  type={field.field_name === "value" ? "number" : field.type}
                  step={field.field_name === "value" ? "0.1" : undefined}
                  // type={field.type}
                  id={field.name}
                  name={field.name}
                  className="border border-gray-300 py-2 px-4 rounded-lg shadow-sm focus:ring-1 focus:ring-blue-500 focus:outline-none"
                />
              )}
            </div>
          ))}
          <button
            type="submit"
            className="mt-2 w-2/3 bg-gradient-to-r from-blue-400 to-blue-300 text-white font-medium text-base py-2 px-6 rounded-lg shadow-md transition-all duration-200 ease-in-out transform hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-200 flex justify-center items-center self-center space-x-2"
          >
            <span className="flex items-center justify-center w-6 h-6 bg-white bg-opacity-20 rounded-full transition-all duration-200">
              <FontAwesomeIcon icon={faPlus} style={{ color: "#ffffff" }} />
            </span>
            <span>Ajouter</span>
          </button>
        </form>
      </div>
    </div>
  );
};

export const Weight = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const child = JSON.parse(localStorage.getItem(id ?? "") || "{}");
  const [temp, setTemp] = useState<WeightType[]>([]);
  const [tempData, setTempData] = useState<WeightType[]>([]);
  const { user } = useAuth();
  const [monitoringDate, setMonitoringDate] = useState(new Date());
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchWeight = async () => {
      try {
        const response = await axios.get(
          `/children/${child.id}/weight-monitoring`,
          {
            headers: {
              Authorization: `Bearer ${user}`,
            },
          }
        );
        setTemp(response.data);
        setTempData(
          response.data.filter(
            (t: any) =>
              new Date(t.dateTime).toDateString() ===
              monitoringDate.toDateString()
          )
        );
      } catch (error) {
        console.error(error);
      }
    };

    fetchWeight();
  }, [child.id, user]);

  const handleNextDate = () => {
    setMonitoringDate(
      new Date(monitoringDate.setDate(monitoringDate.getDate() + 1))
    );
    setTempData(
      temp.filter(
        (t: any) =>
          new Date(t.dateTime).toDateString() === monitoringDate.toDateString()
      )
    );
  };

  const handlePreviousDate = () => {
    setMonitoringDate(
      new Date(monitoringDate.setDate(monitoringDate.getDate() - 1))
    );
    setTempData(
      temp.filter(
        (t: any) =>
          new Date(t.dateTime).toDateString() === monitoringDate.toDateString()
      )
    );
  };

  const dateFormated = (dateTime: string) => {
    const dateObj = new Date(dateTime);
    return dateObj.toLocaleString("fr-FR", {
      hour: "numeric",
      minute: "numeric",
    });
  };

  const handleDeleteCard = async (id: string | undefined) => {
    if (!id) return;
    try {
      await axios.delete(`/children/${child.id}/weight-monitoring/${id}`, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      });
      setTempData((prevCards) => [
        ...prevCards.filter((card) => card.id !== id),
      ]);
      enqueueSnackbar("La donnée a été supprimée", {
        autoHideDuration: 2000,
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } catch (error) {
      console.error("Failed to delete the card:", error);
      enqueueSnackbar("Échec de la suppression de la donnée", {
        autoHideDuration: 2000,
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  };

  return (
    <div className="w-screen">
      {open && (
        <WeightModal
          setOpen={setOpen}
          setTemp={setTemp}
          setTempData={setTempData}
          monitoring="Poids"
          id={id}
          user={user}
        />
      )}
      <section className="flex flex-col justify-between  w-full">
        <Header
          title={t("titles.monitoring")}
          titleColor="blue"
          childInfo={{
            picture: child.image_id,
            name: child.firstname,
            urlRedirection: `/child-profile/${child.id}`,
          }}
          showBackToMenu={true}
        />

        <div className="flex flex-row items-center px-12 justify-between">
          <ButtonIcon
            text={t("titles.Weight")}
            color="black"
            urlRedirection={`/monitoring/${id}`}
            icon={Balance}
            width="1/3"
            disabled={false}
          />
          <button
            onClick={() => setOpen(true)}
            type="button"
            className="mt-4 bg-gradient-to-r from-blue-400 to-blue-300 text-white font-medium text-base py-2 px-6 rounded-lg shadow-md transition-all duration-200 ease-in-out transform hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-200 flex items-center space-x-2"
          >
            <span className="flex items-center justify-center w-6 h-6 bg-white bg-opacity-20 rounded-full transition-all duration-200">
              <FontAwesomeIcon icon={faPlus} style={{ color: "#ffffff" }} />
            </span>
            <span>{t("buttons.new_entry")}</span>
          </button>
        </div>
        <div className="flex flex-row justify-center items-center gap-x-6 md:gap-x-12 px-4 my-4">
          <button onClick={handlePreviousDate}>
            <ArrowLeft />
          </button>
          <h2 className="font-semibold text-blue-400 text-lg md:text-2xl text-center min-w-[200px] md:min-w-[400px]">
            {new Date(monitoringDate).toLocaleDateString("fr-FR", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </h2>
          <button className="disabled:opacity-10" onClick={handleNextDate}>
            <ArrowRight />
          </button>
        </div>
        <div className="grid md:grid-cols-4 grid-cols-2 mt-20 gap-8 px-6">
          {tempData.map((w, index) => (
            <div
              key={index}
              className="flex flex-row items-center justify-center"
            >
              <CardMonitoring
                time={dateFormated(w.dateTime)}
                icone="58-removebg-preview"
                isWarning={true}
                title={w.value + " kg"}
                description=""
                onDelete={() => handleDeleteCard(w.id)}
              />
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};
