import React from "react";
import "./InfoAlert.scss";

interface InfoAlertProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  message: any;
}

const InfoAlert: React.FC<InfoAlertProps> = ({ message }) => {
  return (
    <div className="info-alert">
      <div className="icon">
        <svg viewBox="0 0 24 24" fill="currentColor" width="24px" height="24px">
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-6h2v6zm0-8h-2V7h2v4z" />
        </svg>
      </div>
      <span className="message">{message}</span>
    </div>
  );
};

export default InfoAlert;
