import React, { useEffect } from "react";
import { Register } from "./pages/Register";
import { Login } from "./pages/Login";
import { Children } from "./pages/Children";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { AboutChild } from "./pages/AboutChild";
import { Consultation } from "./pages/Consultation";
import { Administratif } from "./pages/Administratif";
import { CarePlan } from "./pages/CarePlan";
import { Monitoring } from "./pages/Monitoring/Monitoring";
import { Weight } from "./pages/Monitoring/Weight";
import { Poop } from "./pages/Monitoring/Stool";
import { Feeding } from "./pages/Monitoring/Feeding";
import { Treatment } from "./pages/Monitoring/Treatment";
import { Temperature } from "./pages/Monitoring/Temperature";
import { Vomiting } from "./pages/Monitoring/Vomiting";
import ChildProfile from "./pages/Profils/Child";
import { ParentProfil } from "./pages/Profils/Parent";
import { ProtectedLayout } from "./layouts/ProtectedLayout";
import { AuthProvider } from "./hooks/useAuth";
import { PharmacyBox } from "./pages/PharmacyBox/PharmacyBox";
import { NavBar } from "./component/NavBar";
import NewChild from "./pages/NewChild/NewChild";
import NotFound from "./pages/NotFound";
import { Images } from "./pages/Monitoring/Images";
import { UpdateProvider } from "./contexts/UpdateContext";

function App() {
  useEffect(() => {
    document.title = "BA'SSIST - Votre outil de suivi pédiatrique";
  });

  return (
    <UpdateProvider>
      <SnackbarProvider>
        <AuthProvider>
          <BrowserRouter>
            <NavBar />
            <Routes>
              <Route element={<ProtectedLayout />}>
                <Route
                  path="/"
                  element={<Navigate to="/login" replace={false} />}
                />
                <Route path="/register" element={<Register />} />
                <Route path="/login" element={<Login />} />
                <Route path="/children" element={<Children />} />
                <Route path="/new-child" element={<NewChild />} />
                <Route path="/about-child/:id" element={<AboutChild />} />
                <Route path="/consultation/:id" element={<Consultation />} />
                <Route path="/administration/:id" element={<Administratif />} />
                <Route path="/monitoring/:id" element={<Monitoring />} />
                <Route path="/monitoring/weight/:id" element={<Weight />} />
                <Route path="/monitoring/poop/:id" element={<Poop />} />
                <Route path="/monitoring/feeding/:id" element={<Feeding />} />
                <Route path="/monitoring/vomiting/:id" element={<Vomiting />} />
                <Route
                  path="/monitoring/treatment/:id"
                  element={<Treatment />}
                />
                <Route path="/monitoring/images/:id" element={<Images />} />
                <Route
                  path="/monitoring/temperature/:id"
                  element={<Temperature />}
                />
                <Route path="/child-profile/:id" element={<ChildProfile />} />
                <Route path="/parent-profile" element={<ParentProfil />} />
                <Route path="/careplan/:id" element={<CarePlan />} />
                <Route path="/pharmacy-box" element={<PharmacyBox />} />
              </Route>
              {/* 404 : not found */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </SnackbarProvider>
    </UpdateProvider>
  );
}

export default App;
