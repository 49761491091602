/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Table from "../../component/Table/Table";
import "./NewChild.scss";
import Carousel from "./components/Carousel";
import { useAuth } from "../../hooks/useAuth";
import axios from "../../lib/config/axios";
import TableCheck from "../../component/TableCheck/TableCheck";
import Header from "../../component/Header";
import { debounce } from "lodash";
import TableMedicationTreatment from "../../component/Table/TableMedicationTreatment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { manifestationMatchingList } from "../Monitoring/constants";

type GlobalDataType = {
  firstname?: string;
  gender?: "FEMALE" | "MALE";
  birthdate?: Date;
  gestationalAgeAtBirth?: number;
  firstParentId?: number;
  image_id: number;
  size?: number;
  weight?: number;
  usualQuantity: undefined;
  quantityByDays?: number;
};

type AntecedentType = {
  id: number;
  date: string;
  name: string;
};

type AllergyType = {
  id: number;
  date: string;
  name: string;
  manifestation: string;
};

const BackgroundList = [
  { type: "Chirurgicaux", name: "Appendicite" },
  {
    type: "Chirurgicaux",
    name: "Torsion testiculaire",
  },
  {
    type: "Chirurgicaux",
    name: "Fracture (opérée)",
  },
  {
    type: "Chirurgicaux",
    name: "Amygdales / végétations",
  },
  {
    type: "Chirurgicaux",
    name: "Panaris (Ongle incarné)",
  },
  {
    type: "Chirurgicaux",
    name: "Hernie ombilicale",
  },
  {
    type: "Chirurgicaux",
    name: "Invagination intestinale opérée",
  },
  {
    type: "Chirurgicaux",
    name: "Sténose du pylore",
  },
  {
    type: "Chirurgicaux",
    name: "Volvulus intestinal",
  },
  {
    type: "Chirurgicaux",
    name: "Occlusion intestinale",
  },
  {
    type: "Chirurgicaux",
    name: "Communication inter ventriculaire CIV",
  },
  {
    type: "Chirurgicaux",
    name: "Communication inter auriculaire CIA",
  },
  {
    type: "Chirurgicaux",
    name: "Ectopie testiculaire (Testicule non descendu)",
  },
  { type: "Chirurgicaux", name: "Phimosis (opéré)" },
  { type: "Médicaux", name: "Bronchiolite" },
  { type: "Médicaux", name: "Asthme" },
  { type: "Médicaux", name: "Méningite" },
  { type: "Médicaux", name: "Varicelle" },
  { type: "Médicaux", name: "Infection urinaire" },
  { type: "Médicaux", name: "COVID" },
  { type: "Médicaux", name: "Pyélonéphrite" },
  { type: "Médicaux", name: "Constipation" },
  {
    type: "Médicaux",
    name: "Pronation douloureuse",
  },
  {
    type: "Médicaux",
    name: "Syndrome bébé secoué",
  },
  {
    type: "Médicaux",
    name: "Invagination intestinale non opérée",
  },
  {
    type: "Médicaux",
    name: "Adénolymphite mésentérique",
  },
  { type: "Médicaux", name: "TDAH" },
  { type: "Médicaux", name: "Laryngite" },
  { type: "Médicaux", name: "Otite" },
  { type: "Médicaux", name: "Angine" },
  { type: "Médicaux", name: "Scarlatine" },
  { type: "Médicaux", name: "Rougeole" },
  { type: "Médicaux", name: "Roséole" },
  { type: "Médicaux", name: "Phimosis non opéré" },
  { type: "Médicaux", name: "Diabète" },
  {
    type: "Médicaux",
    name: "Convulsion hyperthermique",
  },
  { type: "Médicaux", name: "Épilepsie" },
];

const allergiesTypeMatchingList = {
  FOOD: "Alimentaires",
  ANTIBIOTICS: "Antibiotiques",
  ANTI_INFLAMMATORY: "Anti-inflammatoires",
  OTHER: "Autre",
};

const upperCaseFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

const NewChild = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<string>("step1");
  const [globalData, setGlobalData] = useState<GlobalDataType>({
    firstname: undefined,
    gender: "FEMALE",
    birthdate: undefined,
    gestationalAgeAtBirth: undefined,
    firstParentId: undefined,
    image_id: 0,
    size: undefined,
    weight: undefined,
    usualQuantity: undefined,
  });
  const [allergyList, setAllergyList] = useState<any[]>([]);
  const [medicineList, setMedicineList] = useState<any[]>([]);
  const [vaccinationsList, setVaccinationsList] = useState<any[]>([]);
  const [vacinationData, setVacinationData] = useState<any[]>([]);
  const [allergiesData, setAllergiesData] = useState<any[]>([]);
  const [antecedentsData, setAntecedentsData] = useState<AntecedentType[]>([]);
  const [medicinesData, setMedicinesData] = useState<any[]>([]);
  const [editingAntecedent, setEditingAntecedent] =
    useState<AntecedentType | null>(null);
  const [editingAllergy, setEditingAllergy] = useState<any | null>(null);
  const [medications, setMedications] = useState<any[]>([]);
  const [medicationName, setMedicationName] = useState("");
  const [trigger, setTrigger] = useState(false);

  const debouncedSearch = useCallback(
    debounce(async (query) => {
      try {
        const response = await axios.get(`/medicines/search?value=${query}`, {
          headers: { Authorization: `Bearer ${user}` },
        });
        setMedications(response.data);
      } catch (error) {
        console.error(error);
      }
    }, 300),
    [user]
  );

  useEffect(() => {
    if (trigger && medicationName.length >= 3) {
      debouncedSearch(medicationName);
    }
  }, [trigger, medicationName, debouncedSearch]);

  const handleAddAntecedent = useCallback(
    (item: AntecedentType) => setAntecedentsData((prev) => [...prev, item]),
    []
  );

  const handleAddAllergy = useCallback(
    (item: AllergyType) => {
      const newAllergy = {
        date: item.date,
        name: item.name,
        manifestation: item.manifestation,
        id: item.id,
      };
      setAllergiesData([...allergiesData, newAllergy]);
    },
    [allergiesData]
  );

  // Fonction pour mettre à jour les valeurs du formulaire d'édition de l'allergie
  const handleAllergyInputChange = (
    key: keyof typeof editingAllergy,
    value: string
  ) => {
    setEditingAllergy((prev: any) => (prev ? { ...prev, [key]: value } : null));
  };

  const vacinationColumns = useMemo(() => {
    const columns = [];
    for (let i = 1; i <= 10; i++) {
      columns.push({
        key: `col${i}`,
        value: t(`new_child.form-data.vacinationColumns.col${i}`),
      });
    }
    return columns;
  }, [t]);

  const vacinationLines = useMemo(() => {
    const lines = [];
    for (let i = 1; i <= 10; i++) {
      lines.push({
        key: `line${i}`,
        value: t(`new_child.form-data.vacinationLines.line${i}`),
      });
    }
    return lines;
  }, [t]);

  const allergiesColumns = useMemo(
    () => [
      {
        header: t("mesure.date"),
        accessor: "date",
        type: "month",
        component: "input",
      },
      {
        header: t("new_child.stepper.step3.allergies"),
        accessor: "name",
        component: "select",
        type: "autocomplete",
      },
      {
        header: t("new_child.form_label.manifestation"),
        accessor: "manifestation",
        component: "select",
        type: "manifestationList",
      },
    ],
    []
  );

  const antecedentsColumns = useMemo(
    () => [
      {
        header: t("mesure.date"),
        accessor: "date",
        type: "month",
        component: "input",
      },
      {
        header: t("new_child.stepper.step2.backgrounds"),
        accessor: "name",
        component: "select",
        type: "autocomplete",
      },
    ],
    []
  );

  useEffect(() => {
    if (user === null) return;

    const fetchParentProfile = async () => {
      try {
        const response = await axios.get("/users/profile", {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        });
        const parent = response.data;
        setGlobalData({ ...globalData, firstParentId: parent.id });
      } catch (err) {
        console.error(err);
      }
    };
    const fetchAllergies = async () => {
      try {
        const response = await axios.get("/allergies", {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        });
        const allergiesData = response.data;
        setAllergyList(allergiesData);
      } catch (err) {
        console.error(err);
      }
    };
    const fetchVaccines = async () => {
      try {
        const response = await axios.get("/vaccinations", {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        });
        const vaccineData = response.data;
        setVaccinationsList(vaccineData);
      } catch (err) {
        console.error(err);
      }
    };
    const fetchMedications = async () => {
      try {
        const response = await axios.get("/medicines", {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        });
        const medData = response.data;
        setMedicineList(medData);
      } catch (err) {
        console.error(err);
      }
    };
    fetchParentProfile();
    fetchAllergies();
    fetchVaccines();
    fetchMedications();
  }, [user]);

  const sortedAntecedentsData = [...antecedentsData].sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateB.getTime() - dateA.getTime();
  });

  const onChangeGlobalData = (key: string, value: any) => {
    setGlobalData((prev) => ({ ...prev, [key]: value }));
  };

  const onChangeOtherData = (
    data: never,
    type: "allergy" | "antecedent" | "medicine"
  ) => {
    if (type == "allergy") {
      setAllergiesData([...allergiesData, data]);
    } else if (type == "antecedent") {
      setAntecedentsData([...antecedentsData, data]);
    } else if (type == "medicine") {
      setMedicinesData([...medicinesData, data]);
    }
  };

  const onValidateForm = (e: any) => {
    e.preventDefault();
    const newMedicinesData = medicinesData.map((med: any) => {
      let medecineData = { ...med };
      if (med.dosageAmount) {
        medecineData = {
          ...medecineData,
          dosageAmount: Number(med.dosageAmount),
        };
      }
      if (med.dosageUnit) {
        medecineData = {
          ...medecineData,
          dosageUnit: med.dosageUnit.toUpperCase(),
        };
      }
      if (med.interval) {
        medecineData = { ...medecineData, interval: Number(med.interval) };
      }
      if (med.dailyFrequency) {
        medecineData = {
          ...medecineData,
          dailyFrequency: Number(med.dailyFrequency),
        };
      }
      if (med.days === null) {
        medecineData = { ...medecineData, days: [] };
      }
      return medecineData;
    });

    const { size, weight, birthdate, ...childData } = globalData;
    const date = birthdate && new Date(birthdate);
    const timestamp = date?.toISOString();

    const modifiedAllergies = allergiesData.map((allergy) => ({
      date: allergy.date,
      allergyId: allergyList.find(
        (a: any) => a.name.toLowerCase() === allergy.name.toLowerCase()
      ).id,
      // @ts-expect-error maybe undefined
      manifestation: manifestationMatchingList.find(
        (m: any) => m.name === allergy.manifestation
      ).id,
    }));

    const modifiedVacinations = vacinationData.map((vaccination) => ({
      vaccinationId: vaccination.vaccinationId,
      status: vaccination.status,
      vaccineDate: vaccination.vaccineDate,
    }));

    const formValues = {
      ...childData,
      birthdate: timestamp,
      size,
      weight,
      medicalBackgrounds: antecedentsData,
      vaccinations: modifiedVacinations,
      medicationTreatments: newMedicinesData,
      allergies: modifiedAllergies,
    };

    axios
      .post(
        "/children/create",
        {
          ...formValues,
        },
        {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        }
      )
      .then(() => {
        window.location.href = "/children";
      });
  };

  return (
    <div className="new-child-container">
      <Header
        title={t("titles.new_child")}
        titleColor="blue"
        showBackToMenu={true}
      />
      <section>
        <div className="px-4">
          <Carousel
            active={globalData.image_id}
            onChange={(idx) => onChangeGlobalData("image_id", idx)}
          />
        </div>
        <h2 className="mt-6 mb-2">{t("new_child.stepper.step1")}</h2>
        <form onSubmit={onValidateForm}>
          <div id="step1">
            <div>
              <label>{t("new_child.form_label.firstname")}</label>
              <input
                value={globalData.firstname}
                placeholder="John"
                required
                onChange={(e) =>
                  onChangeGlobalData("firstname", e.target.value)
                }
              />
            </div>
            <div>
              <label>{t("new_child.form_label.genre")}</label>
              <select
                value={globalData.gender}
                onChange={(e) => onChangeGlobalData("gender", e.target.value)}
                required
              >
                <option key="FEMALE" value="FEMALE">
                  {t("new_child.form_label.genres.female")}
                </option>
                <option key="MALE" value="MALE">
                  {t("new_child.form_label.genres.male")}
                </option>
              </select>
            </div>
            <div>
              <label>{t("new_child.form_label.birthdate")}</label>
              <input
                required
                type="date"
                max={new Date().toISOString().split("T")[0]}
                onChange={(e) =>
                  onChangeGlobalData("birthdate", e.target.value)
                }
              />
            </div>
            <div>
              <label>{t("new_child.form_label.size")}</label>
              <input
                type="number"
                placeholder="56"
                onChange={(e) =>
                  onChangeGlobalData("size", Number(e.target.value))
                }
              />
            </div>
            <div>
              <label>{t("new_child.form_label.weight")}</label>
              <input
                type="number"
                placeholder="3,8"
                step="0.1"
                onChange={(e) =>
                  onChangeGlobalData("weight", Number(e.target.value))
                }
              />
            </div>
            <div>
              <label>{t("new_child.form_label.gestation_time")}</label>
              <input
                type="number"
                placeholder="40"
                onChange={(e) =>
                  onChangeGlobalData(
                    "gestationalAgeAtBirth",
                    Number(e.target.value)
                  )
                }
              />
            </div>
            <div>
              <label>{t("new_child.form_label.usual_quantity")}</label>
              <select
                value={globalData.usualQuantity || ""}
                onChange={(e) =>
                  onChangeGlobalData("usualQuantity", Number(e.target.value))
                }
              >
                <option value="" disabled>
                  {t("new_child.form_label.select_quantity")}
                </option>
                {[30, 60, 90, 120, 150, 180, 210, 230, 260].map((quantity) => (
                  <option key={quantity} value={quantity}>
                    {quantity}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label>{t("new_child.form_label.quantity_by_days")}</label>
              <select
                value={globalData.quantityByDays || ""}
                onChange={(e) =>
                  onChangeGlobalData("quantityByDays", Number(e.target.value))
                }
              >
                <option value="" disabled>
                  {t("new_child.form_label.quantity_by_days")}
                </option>
                {[1, 2, 3, 4, 5, 6].map((quantity) => (
                  <option key={quantity} value={quantity}>
                    {quantity}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div id="step2">
            <Table
              columns={antecedentsColumns}
              data={sortedAntecedentsData}
              title={t("new_child.stepper.step2.title")}
              add={(item: AntecedentType) => handleAddAntecedent(item)}
              suggestionsList={BackgroundList}
              remove={(updatedData: AntecedentType[]) =>
                setAntecedentsData(updatedData)
              }
            />
          </div>
          <div id="step3">
            <Table
              columns={allergiesColumns}
              data={allergiesData}
              title={t("new_child.stepper.step3.title")}
              add={(data: any) => handleAddAllergy(data)}
              suggestionsList={allergyList.map((allergy) => ({
                ...allergy,
                name: upperCaseFirstLetter(allergy.name),
                // @ts-expect-error error
                type: allergiesTypeMatchingList[allergy.type],
              }))}
              manifestationList={manifestationMatchingList}
              remove={(data: any[]) => setAllergiesData(data)}
            />
          </div>
          <div id="step4">
            <h2>{t("new_child.stepper.step4")}</h2>
            <TableCheck
              tableList={vaccinationsList}
              data={vacinationData}
              setData={setVacinationData}
              columns={vacinationColumns}
              lines={vacinationLines}
              columnsName={{
                key: "age",
                title: "",
              }}
            />
          </div>
          <div id="step5">
            <TableMedicationTreatment
              title={t("new_child.stepper.step5")}
              data={medicinesData}
              medicationName={medicationName}
              add={(data: never) => onChangeOtherData(data, "medicine")}
              remove={(data: any[]) => setMedicinesData(data)}
              setTrigger={setTrigger}
              setMedicationName={setMedicationName}
              suggestionsList={medications}
            />
          </div>
          <div className="create-button">
            <button
              type="submit"
              className="bg-gradient-to-r from-blue-400 to-blue-300 text-white font-medium text-base py-2 px-6 rounded-lg shadow-md transition-all duration-200 ease-in-out transform hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-200 flex items-center space-x-2"
            >
              <span className="flex items-center justify-center w-6 h-6 bg-white bg-opacity-20 rounded-full transition-all duration-200">
                <FontAwesomeIcon icon={faPlus} style={{ color: "#ffffff" }} />
              </span>
              <span>{t("new_child.create_profile")}</span>
            </button>
          </div>
        </form>
      </section>
    </div>
  );
};

export default NewChild;
