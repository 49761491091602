import React from "react";
import AddChild from "../assets/icons/69-removebg-preview.png";

interface ChildCardProps {
  picture: string;
  name: string;
  urlRedirection: string;
  hoverColor: string;
  height: string;
  fontSize: string;
  imageContainerClassName?: string;
  direction?: "row" | "column";
}

export const ChildCard: React.FC<ChildCardProps> = ({
  picture,
  name,
  urlRedirection,
  hoverColor,
  height,
  fontSize,
  imageContainerClassName,
  direction = "column",
}) => {
  let image;
  if (picture === "AddChild") {
    image = AddChild;
  } else {
    image = require(`../assets/profilPic/${picture}.png`);
  }

  return (
    <button
      className={`flex flex-${direction === "row" ? "row" : "col"} items-center hover:text-${hoverColor} hover:scale-105 transition-transform`}
      onClick={() => (window.location.href = urlRedirection)}
    >
      <div className={imageContainerClassName}>
        <img
          src={image}
          alt="Ajouter un enfant"
          className={`${height} object-cover`}
        />
      </div>
      <h1 className={`text-${fontSize} font-bold`}>{name}</h1>
    </button>
  );
};

interface ChildPictureProps {
  name: string;
  urlRedirection: string;
  hoverColor: string;
}

export const ChildPicture: React.FC<ChildPictureProps> = ({
  name,
  urlRedirection,
  hoverColor,
}) => {
  return (
    <button
      className={`flex flex-col items-center hover:text-${hoverColor}`}
      onClick={() => (window.location.href = urlRedirection)}
    >
      <img src="" alt="Ajouter un enfant" className="h-24" />
      <h1 className="text-2xl font-semibold">{name}</h1>
    </button>
  );
};
