import React, { useState, FormEvent } from "react";
import Logo from "../assets/logo/logo_bassist.png";
import FamilyIllustration from "../assets/illustrations/family.png";
import PavillonLogo from "../assets/logo/logo_pavillon.png";
import axios from "../lib/config/axios";
import { useSnackbar } from "notistack";
import { useAuth } from "../hooks/useAuth";
import { useTranslation } from "react-i18next";

interface FormState {
  email: string;
  pseudo: string;
  password: string;
  passwordConfirmation: string;
}

export const Register = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [form, setForm] = useState<FormState>({
    email: "",
    pseudo: "",
    password: "",
    passwordConfirmation: "",
  });

  if (user) {
    window.location.href = "/children";
  }

  const handleRegister = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      !form.email.length ||
      !form.pseudo.length ||
      !form.password.length ||
      form.password !== form.passwordConfirmation
    ) {
      return;
    }

    axios
      .post("/users/register", form)
      .then((response) => {
        if (response.status !== 201) throw new Error();
        localStorage.setItem("token", response.data.access_token);
        window.location.href = "/children";
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue", {
          autoHideDuration: 2000,
          variant: "error",
        });
      });
  };

  return (
    <div className="flex flex-col justify-center min-h-screen bg-blue-100">
      <div className="relative flex flex-row justify-center mt-12">
        <div className="hidden md:flex flex-col items-center justify-center w-1/2">
          <img
            src={FamilyIllustration}
            alt="Family Illustration"
            className="w-2/3 mb-6"
          />
          <img
            src={PavillonLogo}
            alt="Logo Pavillon de la Mutualité"
            className="w-1/3"
          />
        </div>
        <div className="w-full md:w-1/2 flex justify-center">
          <div className="w-11/12 max-w-lg flex flex-col items-center bg-white p-8 rounded-2xl shadow-lg">
            <img src={Logo} alt="BA'SSIST Logo" className="w-1/2 mb-4" />
            <p className="text-2xl font-bold text-gray-700 mb-6">
              {t("register.i_am_new")}
            </p>
            <form
              className="flex flex-col w-full items-center gap-6"
              onSubmit={handleRegister}
            >
              <input
                type="text"
                onChange={(e) => setForm({ ...form, pseudo: e.target.value })}
                className="w-4/5 border border-gray-300 py-2 px-4 rounded-lg shadow-sm focus:ring-1 focus:ring-blue-500 focus:outline-none"
                placeholder={t("register.pseudo")}
              />
              <input
                type="email"
                onChange={(e) => setForm({ ...form, email: e.target.value })}
                className="w-4/5 border border-gray-300 py-2 px-4 rounded-lg shadow-sm focus:ring-1 focus:ring-blue-500 focus:outline-none"
                placeholder={t("register.email")}
              />
              <input
                type="password"
                onChange={(e) =>
                  setForm({
                    ...form,
                    password: e.target.value,
                  })
                }
                className="w-4/5 border border-gray-300 py-2 px-4 rounded-lg shadow-sm focus:ring-1 focus:ring-blue-500 focus:outline-none"
                placeholder={t("register.password")}
              />
              <input
                type="password"
                onChange={(e) =>
                  setForm({
                    ...form,
                    passwordConfirmation: e.target.value,
                  })
                }
                className="w-4/5 border border-gray-300 py-2 px-4 rounded-lg shadow-sm focus:ring-1 focus:ring-blue-500 focus:outline-none"
                placeholder={t("register.password_confirmation")}
              />
              <div className="flex flex-col w-4/5 gap-3 text-gray-700">
                <label className="flex items-center text-sm">
                  <input
                    type="checkbox"
                    className="h-4 w-4 rounded-full border-gray-300 text-blue-500 focus:ring-blue-400"
                  />
                  <span className="ml-2">
                    {t("register.conditions1")}{" "}
                    <a href="#" className="text-blue-500 underline">
                      {t("register.conditions2")}
                    </a>
                  </span>
                </label>
                <label className="flex items-center text-sm">
                  <input
                    type="checkbox"
                    className="h-4 w-4 rounded-full border-gray-300 text-blue-500 focus:ring-blue-400"
                  />
                  <span className="ml-2">{t("register.remember_me")}</span>
                </label>
              </div>
              <button
                type="submit"
                className="mt-4 w-4/5 bg-gradient-to-r from-blue-500 to-blue-400 text-center text-white font-medium py-2 px-6 rounded-lg shadow-md transition-all duration-200 ease-in-out transform hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300 space-x-2"
              >
                <span>{t("titles.register")}</span>
              </button>
            </form>
            <hr className="w-3/4 my-6 border-t border-gray-300" />
            <div className="text-center">
              <p className="text-lg font-semibold">
                {t("register.already_account")}
              </p>
              <a href="/login" className="text-blue-500 font-bold underline">
                {t("titles.login")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
