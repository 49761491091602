/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";

const Accordion = ({ data, selected, toggleSelected }: any) => {
  const [openAccordion, setOpenAccordion] = useState(-1);

  const toggleAccordion = (index: number) => {
    setOpenAccordion((prevIndex) => (prevIndex === index ? -1 : index));
  };

  return (
    <div>
      {data.map((item: any, index: number) => (
        <div key={index} className="border-b border-slate-200">
          <button
            onClick={() => toggleAccordion(index)}
            className="w-full flex justify-between items-center py-5 text-slate-800"
          >
            <span>{item.categoryName}</span>
            <span className="text-slate-800 transition-transform duration-300">
              {openAccordion === index ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  className="w-4 h-4"
                >
                  <path d="M3.75 7.25a.75.75 0 0 0 0 1.5h8.5a.75.75 0 0 0 0-1.5h-8.5Z" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  className="w-4 h-4"
                >
                  <path d="M8.75 3.75a.75.75 0 0 0-1.5 0v3.5h-3.5a.75.75 0 0 0 0 1.5h3.5v3.5a.75.75 0 0 0 1.5 0v-3.5h3.5a.75.75 0 0 0 0-1.5h-3.5v-3.5Z" />
                </svg>
              )}
            </span>
          </button>
          <div
            className={`transition-all duration-300 ease-in-out overflow-hidden ${
              openAccordion === index ? "max-h-screen" : "max-h-0"
            }`}
          >
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 pb-4">
              {item.listOfPicture.map(
                ({ id, url }: { id: number; url: string }) => (
                  <div key={id} className="relative">
                    <input
                      type="checkbox"
                      id={`checkbox-${id}`}
                      className="absolute top-2 left-2 w-5 h-5 z-10 opacity-0 cursor-pointer"
                      checked={selected === id}
                      onChange={() => toggleSelected(id)}
                    />
                    <label
                      htmlFor={`checkbox-${id}`}
                      className={`block border-2 rounded-lg overflow-hidden transition-all cursor-pointer ${
                        selected === id ? "border-blue-500" : "border-gray-300"
                      }`}
                    >
                      <img
                        src={url}
                        alt={`Image ${id}`}
                        className="w-full h-24 object-cover"
                      />
                    </label>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
