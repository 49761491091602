/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, useState } from "react";
import "./Autocomplete.scss";

// interface Suggestions {
//   name: string;
//   type: string;
// }

interface AutocompleteProps {
  value?: string;
  placeholder?: string;
  onInputChange: (input: string) => void;
  onChange: (input: string) => void;
  getOptionLabel?: (option: any) => string;
  className?: string;
  suggestions?: any[];
}

const SearchAutocomplete = ({
  value,
  placeholder,
  onInputChange,
  onChange,
  getOptionLabel,
  className,
  suggestions,
}: AutocompleteProps) => {
  const [searchTerm, setSearchTerm] = useState(value ?? "");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const handleInputChange = (e: ChangeEvent) => {
    // @ts-expect-error Property 'value' does not exist on type 'EventTarget'.
    const userInput = e.target?.value;
    setSearchTerm(userInput);

    if (userInput && suggestions) {
      const matches = suggestions.filter((suggestion) =>
        suggestion.name.toLowerCase().startsWith(userInput.toLowerCase())
      );
      // @ts-expect-error Argument of type 'string[]' is not assignable to parameter of type 'SetStateAction<never[]>'.
      setFilteredSuggestions(matches);
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
    onInputChange(userInput);
  };

  const handleFocus = () => {
    if (suggestions && searchTerm.length) {
      const matches = suggestions.filter((suggestion) =>
        suggestion.name.toLowerCase().startsWith(searchTerm.toLowerCase())
      );
      // @ts-expect-error Argument of type 'string[]' is not assignable to parameter of type 'SetStateAction<never[]>'.
      setFilteredSuggestions(matches);
    } else {
      // @ts-expect-error Argument of type 'Suggestions[]' is not assignable to parameter of type 'SetStateAction<never[]>'.
      setFilteredSuggestions(suggestions);
    }
    setShowSuggestions(true);
  };


  const handleSuggestionClick = (suggestion: string) => {
    setSearchTerm(suggestion);
    setShowSuggestions(false);
    onChange(suggestion);
  };

  return (
    <div className="search-autocomplete">
      <input
        type="text"
        value={searchTerm}
        onChange={handleInputChange}
        onFocus={handleFocus}
        className={className ?? "search-input"}
        placeholder={placeholder}
      />
      {showSuggestions && filteredSuggestions.length > 0 && (
        <ul className="suggestions-list">
          {filteredSuggestions.map((suggestion: any, index) => (
            <li
              key={index}
              onClick={() => handleSuggestionClick(suggestion.name)}
              className="suggestion-item"
            >
              {getOptionLabel
                ? getOptionLabel(suggestion)
                : suggestion?.name ?? "undefined"}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchAutocomplete;
